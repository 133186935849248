import { ErrorHandlerMixin, VIEW_PERMISSION, DOWNLOAD_PERMISSION, FILTERS_EQUAL, FILTERS_IN, STUCK_CLAIMS_LIST_CLAIMS } from "@kraftheinz/common";
import _ from "lodash";
import { q as queryString } from "./index4.js";
import { a as StuckClaimsUtils, n as normalizeComponent } from "./index.js";
import "vue";
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Homepage", "has-filter-inactive": false, "has-active": false, "has-action": false, "reference": "stuck-claims.claims", "fetch-on-first-mount": false }, scopedSlots: _vm._u([{ key: "customButton", fn: function() {
    return [_c("a-input-search", { attrs: { "placeholder": "Enter Claim Number", "enter-button": "Go", "loading": _vm.isSearchingClaimNumber }, on: { "search": _vm.onSearchClaimNumber } })];
  }, proxy: true }, { key: "customFilter", fn: function() {
    return [_c("a-row", { staticClass: "mr-0 w-100", attrs: { "type": "flex", "gutter": [8, 8] } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "reference": "stuck-claims.common.key-account", "source": "keyAccount", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["keyAccount", "keyAccountDesc"]);
    }, "custom-query": "isProccessScreen=true", "advanced-filters": _vm.queriesByRegion, "clear-data-on-destroy": false, "is-clear-when-params-change": true, "clear-value-on-options-change": false, "value": _vm.customerFilter.value, "max-tag-count": 1, "mode": "multiple", "placeholder": "Select Key Account" }, on: { "change": function($event) {
      return _vm.onFilter("KeyAccount", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "reference": "stuck-claims.common.national-account", "source": "nationalAccount", "advanced-filters": _vm.nationalAccountFilterAdvanced, "custom-query": "isProccessScreen=true", "value": _vm.nationalAccountFilter.value, "delete-filter": "IsInactive", "clear-data-on-destroy": false, "is-clear-when-params-change": true, "clear-value-on-options-change": false, "max-tag-count": 1, "mode": "multiple", "placeholder": "Select National Account" }, on: { "change": function($event) {
      return _vm.onFilter("NationalAccount", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "reference": "stuck-claims.common.ppg", "source": "code", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "source-description": "description", "advanced-filters": _vm.ppgCodeFilterAdvanced, "custom-query": "isProccessScreen=true", "value": _vm.ppgCodeFilter.value, "delete-filter": "IsInactive", "clear-data-on-destroy": false, "is-clear-when-params-change": true, "clear-value-on-options-change": false, "max-tag-count": 1, "mode": "multiple", "placeholder": "Select PPG" }, on: { "change": function($event) {
      return _vm.onFilter("PPG", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("a-button", { attrs: { "icon": "close-circle" }, on: { "click": _vm.onClearFiltersClick } }, [_vm._v(" Clear filters ")])], 1)], 1)];
  }, proxy: true }, { key: "create-buttons", fn: function() {
    return [_c("a-button", { attrs: { "disabled": !_vm.can(_vm.permissions.download), "loading": _vm.isExportingNoMapping, "type": "primary" }, on: { "click": _vm.exportFileNoMapping } }, [_vm._v(" Claim Unlink ")]), _c("a-button", { staticClass: "ml-2", attrs: { "disabled": !_vm.can(_vm.permissions.download), "loading": _vm.isExporting, "type": "primary" }, on: { "click": _vm.exportFile } }, [_vm._v(" Export Claims List ")])];
  }, proxy: true }]) }, [_c("url-field", { key: "ClaimGroup", attrs: { "base-url": _vm.getProcessScreenUrl, "data-index": "claimGroup", "query": _vm.getProcessScreenQueryString("claimNumber"), "title": "Claim Number", "sorter": true } }), _c("text-field", { key: "PromoId", attrs: { "data-index": "promoId", "title": "Promo ID", "sorter": true } }), _c("text-field", { key: "KeyAccount", attrs: { "data-index": "keyAccount", "title": "Key Account", "parse": _vm.parseKeyAccount, "sorter": true } }), _c("text-field", { key: "nationalAccount", attrs: { "data-index": "nationalAccount", "title": "National Account", "sorter": true } }), _c("url-field", { key: "PromotionalGroupCode", attrs: { "base-url": _vm.getProcessScreenUrl, "data-index": "promotionalGroupCode", "query": _vm.getProcessScreenQueryString("ppg"), "title": "PPG", "parse": _vm.parsePG, "sorter": true } })], 1);
};
var staticRenderFns$5 = [];
var ListClaimsAu_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".action__icon-container[data-v-25f7f26e]{display:inline-block;margin-left:2px;margin-right:2px}.action__icon-container[data-v-25f7f26e] .ant-btn>.anticon{margin-top:3px}[data-v-25f7f26e] .list__table .align-items-end{align-items:flex-start!important}\n")();
const usdFormatter$1 = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});
const __vue2_script$5 = {
  name: "ListClaimAu",
  inject: ["can", "crud", "getSelectedCountry", "resources"],
  mixins: [StuckClaimsUtils, ErrorHandlerMixin],
  data() {
    const claimsProps = this.resources["stuck-claims.claims"];
    const processScreenProps = this.resources["stuck-claims.claims.process-screen"];
    return {
      filterForm: {
        Region: void 0,
        KeyAccount: void 0,
        NationalAccount: void 0,
        PPG: void 0
      },
      claimsProps,
      isExporting: false,
      isSearchingClaimNumber: false,
      permissions: {
        view: VIEW_PERMISSION,
        download: DOWNLOAD_PERMISSION
      },
      processScreenProps,
      subCatList: [],
      isExportingNoMapping: false
    };
  },
  computed: {
    hasViewPermission() {
      return this.can(this.permissions.view);
    },
    regionFilter() {
      return [
        "Region",
        { operator: FILTERS_EQUAL, value: this.selectedCountry }
      ];
    },
    customerFilter() {
      return this.claimsProps.crud.getFilter("KeyAccount") || "";
    },
    ppgCodeFilter() {
      return this.claimsProps.crud.getFilter("PPG") || "";
    },
    ppgCodeFilterAdvanced() {
      return [
        this.regionFilter,
        [
          "KeyAccount",
          {
            operator: FILTERS_IN,
            value: this.filterForm.KeyAccount
          }
        ],
        [
          "NationalAccount",
          { operator: FILTERS_IN, value: this.filterForm.NationalAccount }
        ]
      ];
    },
    queriesByRegion() {
      return [this.regionFilter];
    },
    selectedCountry() {
      return this.getSelectedCountry();
    },
    nationalAccountFilter() {
      return this.claimsProps.crud.getFilter("NationalAccount") || "";
    },
    nationalAccountFilterAdvanced() {
      return [
        this.regionFilter,
        [
          "KeyAccount",
          {
            operator: FILTERS_IN,
            value: this.filterForm.KeyAccount
          }
        ]
      ];
    }
  },
  created() {
    const filters = this.claimsProps.crud.getFilters();
    if (filters.size) {
      this.filterForm.KeyAccount = filters.get("KeyAccount").value;
      this.filterForm.NationalAccount = filters.get("NationalAccount").value;
    }
    this.fetchList();
  },
  methods: {
    getProcessScreenUrl() {
      return "/stuck-claims/process-screen";
    },
    getProcessScreenQueryString(mode) {
      return (record) => {
        switch (mode) {
          case "claimNumber":
            const queryString$1 = this.customerFilter.value ? {
              claimNumber: record.claimGroup,
              region: record.region,
              keyAccount: record.keyAccount
            } : {
              claimNumber: record.claimGroup,
              region: record.region
            };
            return queryString.stringify(queryString$1);
          case "ppg":
            return queryString.stringify({
              promotionalGroupCode: record.promotionalGroupCode,
              promotionalGroupDescription: record.promotionalGroupDescription,
              region: record.region,
              keyAccount: record.keyAccount
            });
        }
      };
    },
    fetchList: _.debounce(function() {
      try {
        this.claimsProps.crud.fetchList();
      } catch (error) {
        if (error.response.status === 409) {
          this.claimsProps.crud.clearList();
          this.claimsProps.crud.deletePagination();
        }
      }
    }, 500),
    setDefaultValue(key) {
      switch (key) {
        case "KeyAccount":
          this.claimsProps.crud.deleteFilter("NationalAccount");
          this.claimsProps.crud.deleteFilter("PPG");
          break;
        case "NationalAccount":
          this.claimsProps.crud.deleteFilter("PPG");
          break;
      }
    },
    onFilter(key, value) {
      this.claimsProps.crud.setPagination({ page: 1 });
      if (!value || !value.length) {
        this.filterForm[key] = void 0;
        this.claimsProps.crud.deleteFilter(key);
      } else {
        this.filterForm[key] = value;
        if (key === "Region") {
          this.claimsProps.crud.setFilter(key, {
            operator: FILTERS_EQUAL,
            value
          });
        } else {
          this.claimsProps.crud.setFilter(key, {
            operator: FILTERS_IN,
            value
          });
        }
      }
      this.claimsProps.crud.deleteFilter("IsInactive");
      this.fetchList();
      this.claimsProps.crud.updateEntity("createForm", {
        resourceKey: key,
        value
      });
    },
    onClearFiltersClick() {
      this.filterForm = {
        Region: void 0,
        KeyAccount: void 0,
        NationalAccount: void 0,
        PPG: void 0
      };
      this.claimsProps.crud.clearFilters();
      this.claimsProps.crud.deleteQueryStrings();
      this.claimsProps.crud.setIsAdvancedFiltered(false);
      this.claimsProps.crud.deleteFilter("IsInactive");
      this.claimsProps.crud.setQueryString("region", this.selectedCountry);
      this.fetchList();
    },
    async onSearchClaimNumber(value) {
      if (!value)
        return;
      this.isSearchingClaimNumber = true;
      this.processScreenProps.crud.setQueryString("region", this.selectedCountry);
      this.processScreenProps.crud.setQueryString("claimNumber", value);
      try {
        await this.processScreenProps.crud.fetchList();
        const claims = this.processScreenProps.crud.getList();
        if (claims && claims.length) {
          this.$router.push({
            name: "ProcessScreen",
            query: {
              claimNumber: value,
              region: this.selectedCountry
            }
          });
        } else {
          this.$notification.error({
            message: "Claim Number is not found"
          });
        }
      } catch (error) {
        console.log("err: ", error);
      } finally {
        this.isSearchingClaimNumber = false;
      }
    },
    formatCurrency(value) {
      return usdFormatter$1.format(value);
    },
    parseKeyAccount(keyAccountCode, record) {
      return `${keyAccountCode} - ${record.keyAccountDesc}`;
    },
    parsePG(promotionalGroupCode, record) {
      return `${promotionalGroupCode} - ${record.promotionalGroupDescription}`;
    },
    async exportFile() {
      this.isExporting = true;
      const filterString = this.claimsProps.crud.getFilterQuery();
      const queryString$1 = this.claimsProps.crud.getQueryStrings();
      const params = queryString.parse(`${filterString}&${queryString$1}`);
      await this.downloadFileUtils(`${this.claimsProps.apiUrl}/stuck-claims/claims/export`, "text/csv;charset=utf-8;", params);
      this.isExporting = false;
    },
    async exportFileNoMapping() {
      this.isExportingNoMapping = true;
      const filterString = this.claimsProps.crud.getFilterQuery();
      const queryString$1 = this.claimsProps.crud.getQueryStrings();
      const params = queryString.parse(`${filterString}&${queryString$1}`);
      try {
        await this.downloadFileUtils(`${this.claimsProps.apiUrl}/stuck-claims/claims/export-claim-not-match`, "text/csv;charset=utf-8;", params);
      } catch (error) {
        this.displayErrorNotification(error);
      } finally {
        this.isExportingNoMapping = false;
      }
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "25f7f26e", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var ListClaimAu$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-promo" }, [_c("list-claim-au")], 1);
};
var staticRenderFns$4 = [];
var index_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".filter-box[data-v-270e2926]{width:350px;padding-bottom:5px}.action__icon-container[data-v-270e2926]{display:inline-block;margin-left:2px;margin-right:2px}.action__icon-container[data-v-270e2926] .ant-btn>.anticon{margin-top:3px}[data-v-270e2926] .list__table .align-items-end{align-items:flex-start!important}\n")();
const __vue2_script$4 = {
  components: { ListClaimAu: ListClaimAu$1 }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, "270e2926", null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var ListClaimAu = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Homepage", "has-filter-inactive": false, "has-active": false, "has-action": false, "reference": "stuck-claims.claims", "fetch-on-first-mount": false }, scopedSlots: _vm._u([{ key: "customButton", fn: function() {
    return [_c("a-input-search", { attrs: { "placeholder": "Enter Claim Number", "enter-button": "Go", "loading": _vm.isSearchingClaimNumber }, on: { "search": _vm.onSearchClaimNumber } })];
  }, proxy: true }, { key: "customFilter", fn: function() {
    return [_c("a-row", { staticClass: "mr-0", attrs: { "type": "flex", "gutter": [8, 8] } }, [_c("a-col", [_c("div", { staticClass: "filter-box" }, [_c("select-input", { attrs: { "reference": "stuck-claims.common.key-account", "source": "keyAccount", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["keyAccount", "keyAccountDesc"]);
    }, "custom-query": "isProccessScreen=true", "advanced-filters": _vm.queriesByRegion, "clear-data-on-destroy": true, "value": _vm.customerFilter.value, "max-tag-count": 1, "mode": "multiple", "placeholder": "Select Key Account" }, on: { "change": function($event) {
      return _vm.onFilter("KeyAccount", $event);
    } } })], 1)]), _c("a-col", [_c("div", { staticClass: "filter-box" }, [_c("select-input", { attrs: { "reference": "stuck-claims.common.sub-category", "source": "code", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "source-description": "description", "advanced-filters": _vm.subCategoryQueries, "custom-query": "isProccessScreen=true", "clear-data-on-destroy": true, "value": _vm.subCategoriesFilter.value, "max-tag-count": 1, "mode": "multiple", "placeholder": "Select Sub Category" }, on: { "change": function($event) {
      return _vm.onFilter("SubCategory", $event);
    } } })], 1)]), _c("a-col", [_c("div", { staticClass: "filter-box" }, [_c("select-input", { attrs: { "reference": "stuck-claims.common.gl", "source": "code", "source-label": "desc", "value": _vm.glFilter.value, "mode": "multiple", "placeholder": "Select GL" }, on: { "change": function($event) {
      return _vm.onFilter("GL", $event);
    } } })], 1)]), _c("a-col", [_c("div", { staticClass: "filter-box" }, [_c("select-input", { attrs: { "reference": "stuck-claims.common.ppg", "source": "code", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "source-description": "description", "clear-data-on-destroy": true, "advanced-filters": _vm.ppgCodeFilterAdvanced, "custom-query": "isProccessScreen=true", "value": _vm.ppgCodeFilter.value, "max-tag-count": 1, "delete-filter": "IsInactive", "mode": "multiple", "placeholder": "Select PPG" }, on: { "change": function($event) {
      return _vm.onFilter("PPG", $event);
    } } })], 1)]), _c("a-col", [_c("div", { staticClass: "filter-box" }, [_c("a-button", { attrs: { "icon": "close-circle" }, on: { "click": _vm.onClearFiltersClick } }, [_vm._v(" Clear filters ")])], 1)])], 1)];
  }, proxy: true }, { key: "create-buttons", fn: function() {
    return [_c("a-button", { attrs: { "disabled": !_vm.can(_vm.permissions.download), "loading": _vm.isExportingNoMapping, "type": "primary" }, on: { "click": _vm.exportFileNoMapping } }, [_vm._v(" Claim Unlink ")]), _c("a-button", { staticClass: "ml-2", attrs: { "disabled": !_vm.can(_vm.permissions.download), "loading": _vm.isExporting, "type": "primary" }, on: { "click": _vm.exportFile } }, [_vm._v(" Export Claims List ")])];
  }, proxy: true }]) }, [_c("text-field", { key: "KeyAccount", attrs: { "data-index": "keyAccount", "title": "Key Account", "parse": _vm.parseKeyAccount, "sorter": true } }), _c("text-field", { key: "Gl", attrs: { "data-index": "gl", "title": "GL", "sorter": true } }), _c("url-field", { key: "promotionalGroupCode", attrs: { "base-url": _vm.getProcessScreenUrl, "clickable": _vm.hasViewPermission, "data-index": "promotionalGroupCode", "parse": _vm.parsePG, "query": _vm.getProcessScreenQueryString("ppg"), "title": "Promotional Group", "sorter": true } }), _c("text-field", { key: "SubCategoryCode", attrs: { "data-index": "subCategoryCode", "title": "Sub Category", "parse": _vm.parseSubCategory, "sorter": true } }), _c("text-field", { key: "ClaimType", attrs: { "data-index": "claimType", "title": "Claim Type", "sorter": true } }), _c("text-field", { key: "Lines", attrs: { "data-index": "lines", "title": "Lines", "sorter": true } }), _c("text-field", { key: "Value", attrs: { "data-index": "value", "title": "Value", "sorter": true, "parse": _vm.formatCurrency } })], 1);
};
var staticRenderFns$3 = [];
var ListClaimNz_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".filter-box[data-v-12edda8e]{width:350px;padding-bottom:5px}.action__icon-container[data-v-12edda8e]{display:inline-block;margin-left:2px;margin-right:2px}.action__icon-container[data-v-12edda8e] .ant-btn>.anticon{margin-top:3px}[data-v-12edda8e] .list__table .align-items-end{align-items:flex-start!important}\n")();
const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});
const __vue2_script$3 = {
  name: "ListClaimNz",
  inject: ["can", "crud", "getSelectedCountry", "resources"],
  mixins: [StuckClaimsUtils, ErrorHandlerMixin],
  data() {
    const claimsProps = this.resources["stuck-claims.claims"];
    const processScreenProps = this.resources["stuck-claims.claims.process-screen"];
    return {
      claimsProps,
      filterForm: {
        Region: void 0,
        KeyAccount: void 0,
        SubCategory: void 0,
        Ppg: void 0
      },
      isExporting: false,
      isSearchingClaimNumber: false,
      permissions: {
        view: VIEW_PERMISSION,
        download: DOWNLOAD_PERMISSION
      },
      processScreenProps,
      subCatList: [],
      isExportingNoMapping: false
    };
  },
  computed: {
    hasViewPermission() {
      return this.can(this.permissions.view);
    },
    regionFilter() {
      return [
        "Region",
        { operator: FILTERS_EQUAL, value: this.selectedCountry }
      ];
    },
    promoIdFilter() {
      return this.claimsProps.crud.getFilter("PromoId") || "";
    },
    customerFilter() {
      return this.claimsProps.crud.getFilter("KeyAccount") || "";
    },
    subCategoriesFilter() {
      return this.claimsProps.crud.getFilter("SubCategory") || "";
    },
    glFilter() {
      return this.claimsProps.crud.getFilter("GL") || "";
    },
    ppgCodeFilter() {
      return this.claimsProps.crud.getFilter("PPG") || "";
    },
    ppgCodeFilterAdvanced() {
      return [
        this.regionFilter,
        [
          "KeyAccount",
          { operator: FILTERS_IN, value: this.filterForm.KeyAccount }
        ],
        ["PPG", { operator: FILTERS_IN, value: this.filterForm.Ppg }],
        [
          "SubCategory",
          { operator: FILTERS_IN, value: this.filterForm.SubCategory }
        ]
      ];
    },
    queriesByRegion() {
      return [this.regionFilter];
    },
    subCategoryQueries() {
      return [
        this.regionFilter,
        [
          "KeyAccount",
          { operator: FILTERS_IN, value: this.filterForm.KeyAccount }
        ]
      ];
    },
    selectedCountry() {
      return this.getSelectedCountry();
    }
  },
  created() {
    this.fetchList();
  },
  methods: {
    getProcessScreenUrl() {
      return "/stuck-claims/process-screen";
    },
    getProcessScreenQueryString(mode) {
      return (record) => {
        switch (mode) {
          case "claimNumber":
            return queryString.stringify({
              claimNumber: record.claimNumber,
              region: record.region
            });
          case "ppg":
            return queryString.stringify({
              claimType: record.claimType,
              gL: record.gl,
              keyAccount: record.keyAccount,
              promotionalGroupCode: record.promotionalGroupCode,
              promotionalGroupDescription: record.promotionalGroupDescription,
              region: record.region
            });
        }
      };
    },
    fetchList: _.debounce(function() {
      try {
        this.claimsProps.crud.fetchList();
      } catch (error) {
        if (error.response.status === 409) {
          this.claimsProps.crud.clearList();
          this.claimsProps.crud.deletePagination();
        }
      }
    }, 800),
    onFilter(key, value) {
      this.claimsProps.crud.setQueryString("region", this.selectedCountry);
      this.claimsProps.crud.setPagination({
        page: 1,
        pageSize: 40
      });
      if (value === 0 || value.length == 0) {
        this.filterForm[key] = void 0;
        this.claimsProps.crud.deleteFilter(key);
      } else {
        this.filterForm[key] = value;
        if (key === "Region") {
          this.claimsProps.crud.setFilter(key, {
            operator: FILTERS_EQUAL,
            value
          });
        } else {
          this.claimsProps.crud.setFilter(key, {
            operator: FILTERS_IN,
            value
          });
        }
      }
      this.claimsProps.crud.deleteFilter("IsInactive");
      this.fetchList();
      this.claimsProps.crud.updateEntity("createForm", {
        resourceKey: key,
        value
      });
    },
    onClearFiltersClick() {
      this.filterForm.Region = void 0;
      this.claimsProps.crud.clearFilters();
      this.claimsProps.crud.deleteQueryStrings();
      this.claimsProps.crud.setIsAdvancedFiltered(false);
      this.claimsProps.crud.deleteFilter("IsInactive");
      this.claimsProps.crud.setQueryString("region", this.selectedCountry);
      this.fetchList();
    },
    async onSearchClaimNumber(value) {
      if (!value)
        return;
      this.isSearchingClaimNumber = true;
      this.processScreenProps.crud.setQueryString("region", this.selectedCountry);
      this.processScreenProps.crud.setQueryString("claimNumber", value);
      this.processScreenProps.crud.deleteFilter("IsInactive");
      try {
        await this.processScreenProps.crud.fetchList();
        const claims = this.processScreenProps.crud.getList();
        if (claims && claims.length) {
          this.$router.push({
            name: "ProcessScreen",
            query: {
              claimNumber: value,
              region: this.selectedCountry
            }
          });
        } else {
          this.$notification.error({
            message: "Claim Number is not found"
          });
        }
      } catch (error) {
        console.log("err: ", error);
      } finally {
        this.isSearchingClaimNumber = false;
      }
    },
    formatCurrency(value) {
      return usdFormatter.format(value);
    },
    parseKeyAccount(keyAccountCode, record) {
      return `${keyAccountCode} - ${record.keyAccountDesc}`;
    },
    parsePG(promotionalGroupCode, record) {
      return `${promotionalGroupCode} - ${record.promotionalGroupDescription}`;
    },
    parseSubCategory(subCategoryCode, record) {
      return `${subCategoryCode} - ${record.subCategoryDescription}`;
    },
    async exportFile() {
      this.isExporting = true;
      const filterString = this.claimsProps.crud.getFilterQuery();
      const queryString$1 = this.claimsProps.crud.getQueryStrings();
      const params = queryString.parse(`${filterString}&${queryString$1}`);
      await this.downloadFileUtils(`${this.claimsProps.apiUrl}/stuck-claims/claims/export`, "text/csv;charset=utf-8;", params);
      this.isExporting = false;
    },
    async exportFileNoMapping() {
      this.isExportingNoMapping = true;
      const filterString = this.claimsProps.crud.getFilterQuery();
      const queryString$1 = this.claimsProps.crud.getQueryStrings();
      const params = queryString.parse(`${filterString}&${queryString$1}`);
      try {
        await this.downloadFileUtils(`${this.claimsProps.apiUrl}/stuck-claims/claims/export-claim-not-match`, "text/csv;charset=utf-8;", params);
      } catch (error) {
        this.displayErrorNotification(error);
      } finally {
        this.isExportingNoMapping = false;
      }
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "12edda8e", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ListClaimNz$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-promo" }, [_c("list-claim-nz")], 1);
};
var staticRenderFns$2 = [];
const __vue2_script$2 = {
  components: { ListClaimNz: ListClaimNz$1 }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ListClaimNz = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_vm.selectedCountry === "AU" ? _c("list-claim-au") : _vm.selectedCountry === "NZ" ? _c("list-claim-nz") : _vm._e()], 1);
};
var staticRenderFns$1 = [];
var ListClaims_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "ListClaims",
  components: { ListClaimAu, ListClaimNz },
  inject: ["can", "getSelectedCountry", "resources"],
  data() {
    const claimsProps = this.resources["stuck-claims.claims"];
    return {
      claimsProps
    };
  },
  computed: {
    selectedCountry() {
      return this.getSelectedCountry();
    }
  },
  watch: {
    selectedCountry(newVal) {
      this.claimsProps.crud.clearFilters();
      this.claimsProps.crud.deleteQueryStrings();
      this.claimsProps.crud.clearList();
      this.claimsProps.crud.setPagination({
        page: 1,
        pageSize: 40
      });
      this.claimsProps.crud.setSorter({ columnKey: null, order: null });
      this.claimsProps.crud.deleteFilter("IsInactive");
      this.claimsProps.crud.setQueryString("region", newVal);
    }
  },
  created() {
    this.claimsProps.crud.deleteFilter("IsInactive");
    this.claimsProps.crud.setQueryString("region", this.selectedCountry);
    this.claimsProps.crud.setPagination({
      page: 1,
      pageSize: 40
    });
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "723e0423", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListClaims = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-promo" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "stuck-claims.common.region", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "stuck-claims.common.key-account", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "stuck-claims.common.sub-category", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "stuck-claims.common.gl", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "stuck-claims.common.ppg", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "stuck-claims.common.national-account", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "page": _vm.page, "resources": _vm.resources } }, [_c("list-claims")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListClaims
  },
  data() {
    return {
      apiUrl,
      page: STUCK_CLAIMS_LIST_CLAIMS,
      itemsMenu: [
        {
          key: "homepage",
          title: "Homepage",
          path: ""
        }
      ],
      resources: [
        { name: "stuck-claims.claims" },
        { name: "stuck-claims.claims.process-screen" }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
